exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-caratteristiche-per-aprire-un-call-center-js": () => import("./../../../src/pages/caratteristiche-per-aprire-un-call-center.js" /* webpackChunkName: "component---src-pages-caratteristiche-per-aprire-un-call-center-js" */),
  "component---src-pages-contatti-js": () => import("./../../../src/pages/contatti.js" /* webpackChunkName: "component---src-pages-contatti-js" */),
  "component---src-pages-demo-index-js": () => import("./../../../src/pages/demo/index.js" /* webpackChunkName: "component---src-pages-demo-index-js" */),
  "component---src-pages-demo-richiesta-inviata-js": () => import("./../../../src/pages/demo/richiesta-inviata.js" /* webpackChunkName: "component---src-pages-demo-richiesta-inviata-js" */),
  "component---src-pages-hardware-js": () => import("./../../../src/pages/hardware.js" /* webpackChunkName: "component---src-pages-hardware-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-informativa-sulla-privacy-js": () => import("./../../../src/pages/informativa-sulla-privacy.js" /* webpackChunkName: "component---src-pages-informativa-sulla-privacy-js" */),
  "component---src-pages-la-rivoluzione-passa-dal-cloud-js": () => import("./../../../src/pages/la-rivoluzione-passa-dal-cloud.js" /* webpackChunkName: "component---src-pages-la-rivoluzione-passa-dal-cloud-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-rendi-produttiva-la-tua-attivita-con-il-crm-marketing-js": () => import("./../../../src/pages/rendi-produttiva-la-tua-attivita-con-il-crm-marketing.js" /* webpackChunkName: "component---src-pages-rendi-produttiva-la-tua-attivita-con-il-crm-marketing-js" */),
  "component---src-pages-software-per-call-center-js": () => import("./../../../src/pages/software-per-call-center.js" /* webpackChunkName: "component---src-pages-software-per-call-center-js" */),
  "component---src-pages-termini-condizioni-dellapplicazione-thelgo-js": () => import("./../../../src/pages/termini-condizioni-dellapplicazione-thelgo.js" /* webpackChunkName: "component---src-pages-termini-condizioni-dellapplicazione-thelgo-js" */),
  "component---src-pages-termini-e-condizioni-dell-applicazione-thelgo-js": () => import("./../../../src/pages/termini-e-condizioni-dell-applicazione-thelgo.js" /* webpackChunkName: "component---src-pages-termini-e-condizioni-dell-applicazione-thelgo-js" */),
  "component---src-pages-vuoi-gestire-un-call-center-o-contact-center-js": () => import("./../../../src/pages/vuoi-gestire-un-call-center-o-contact-center.js" /* webpackChunkName: "component---src-pages-vuoi-gestire-un-call-center-o-contact-center-js" */),
  "component---src-templates-caratteristiche-js": () => import("./../../../src/templates/caratteristiche.js" /* webpackChunkName: "component---src-templates-caratteristiche-js" */),
  "component---src-templates-single-article-js": () => import("./../../../src/templates/single-article.js" /* webpackChunkName: "component---src-templates-single-article-js" */),
  "component---src-templates-single-product-js": () => import("./../../../src/templates/single-product.js" /* webpackChunkName: "component---src-templates-single-product-js" */)
}

